<template>
  <div class='plugInEn'>
    <div class="bg"></div>
    <div class="cantainer">
      <div class="intro">
        <h1 class="title">LET VOLUMETRIC VIDEO CHANGE LIFE</h1>
        <div class="description">
        Our technology can <span>provide</span> an immersive experience <span>for your</span> customers
        </div>
        <div class="intro-btn">
          <div class="free-trial" @click="animationFree">FREE TRIAL</div>
          <div class="learn-more" @click="animationLearn">LEARN MORE</div>
        </div>
      </div>
      <div class="imgs">
        <el-row>
          <el-col :span="8">
            <div class="capture">
              <img src="https://holodata.prometh.xyz/webMetaTrade/plugen-CAPTURE.png" alt="">
              <div>CAPTURE/DOWNLOAD</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="editing">
              <img src="https://holodata.prometh.xyz/webMetaTrade/plugen-EDITING.png" alt="">
              <div>EDITING&USE PLUGIN</div>
              
            </div>
          </el-col>
          <el-col :span="8">
            <div class="play">
              <img src="https://holodata.prometh.xyz/webMetaTrade/plugen-PLAY.png">
              <div>PLAY</div>
              
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="useCases">
        <div class="useCases-title">USE CASES</div>
        <div class="useCases-imgs">
            <div class="useCases-box">
              <img src="https://holodata.prometh.xyz/webMetaTrade/plugen-use+case1.png" alt="">
              <div>“MV”</div>
            </div>
            <div class="useCases-box">
              <img src="https://holodata.prometh.xyz/webMetaTrade/plugen-use+case2.png" alt="">
              <div>“AR”</div>

            </div>
            <div class="useCases-box useCases-box1">
              <img src="https://holodata.prometh.xyz/webMetaTrade/plugen-use+case3.png" alt="">
              <div>“CONCERT”</div>

            </div>
        </div>
      </div>
      <div class="haloStudio">
        <div class="haloStudio-title">HALO STUDIO</div>
        <div class="haloStudio-intro">VOLUMETRIC VIDEO EDITING SOFTWARE</div>
        <div class="haloStudio-img">
          <video 
            src="https://holodata.prometh.xyz/PluginPackage/publish/Tutorial/halo+tutorial.mp4"
              width="100%"
              muted
              autoplay
              loop
          ></video>
        </div>
        <div class="haloStudio-description1">
          This software allows you to modify andimprove all volumetric sequences to be finally exported to the most suitable cormpressed format (.Mp4),directly applied<br/> to the project through the Unity/UE engine plugin
        </div>
        <div class="haloStudio-description2">
          Process TRACKING points on any of your sequences <br/> Compositing MULTIPLE volumetric videos<br/>
Compressed export with support for multiple resolutions and rates<br/> More features are waiting for you to discover
        </div>
        <div class="haloStudio-description3">
          The mp4 format of Prometheus can be applied in Unity and UE plug-ins,On Windows Android and iOS platforms
        </div>
      </div>
       <div class="createNow">
        <div class="createNow-title">CREATE NOW</div>
        <div class="createNow-imgs">
                <video
        width="100%"
        muted
        autoplay
        loop
        src="https://holodata.prometh.xyz/PluginPackage/publish/Tutorial/catchlight.mp4"
      ></video>
        </div>
        <div class="createNow-text">Download our Volumetric Video editing software and start designing your app by using Unity3D, Unreal Engine plug-in</div>
        <div class="createNow-btn-box">
          <div class="createNow-btn1" @click="downloadHalo">HALO STUDIO</div>
          <div class="createNow-btn2" @click="downloadUnity">PLUGIN-UNITY</div>
          <div class="createNow-btn3" @click="downloadUE">PLUGIN-UE</div>
        </div>
      </div>
    </div>
    <downloadDialog 
      :dialogFormVisible="dialogFormVisible" 
      :downloadForm = "downloadForm"
      @downloadClose = "downloadClose"
    />
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import downloadDialog from "@/components/downloadDialog"
import {animate} from '@/utils/animate'

export default {
  name: "plugInEn",
  data() {
    return {
      dialogFormVisible:false,
      downloadForm: {
        url: '',
        name: ''
      }
    }
  },
  components: {
    downloadDialog
  },
  created() {},
  mounted() {},
  methods: {
    downloadClose() {
      this.dialogFormVisible = false
    },
    downloadHalo() {
      if (getItem('DownloadCompleted')||this.$store.state.token) {
        download('https://holodata.prometh.xyz/PluginPackage/publish/HaloStudio.rar','HaloStudio.rar')
      } else {
        this.downloadForm = {
          url : 'https://holodata.prometh.xyz/PluginPackage/publish/HaloStudio.rar',
          name : 'HaloStudio.rar'
        }
        this.dialogFormVisible = true
      }
      
    },
    downloadUnity() {
      if (getItem('DownloadCompleted')||this.$store.state.token) {
        download('https://holodata.prometh.xyz/PluginPackage/publish/UnityPlugin.rar','UnityPlugin.rar')
      } else {
        this.downloadForm = {
          url : 'https://holodata.prometh.xyz/PluginPackage/publish/UnityPlugin.rar',
          name : 'UnityPlugin.rar'
        }
        this.dialogFormVisible = true
      }
      
    },
    downloadUE() {
      if (getItem('DownloadCompleted')||this.$store.state.token) {
        download('https://holodata.prometh.xyz/PluginPackage/publish/UEPlugin.rar','UEPlugin.rar')
      } else {
        this.downloadForm = {
          url : 'https://holodata.prometh.xyz/PluginPackage/publish/UEPlugin.rar',
          name : 'UEPlugin.rar'
        }
        this.dialogFormVisible = true
      }
      
    },
    download(url,name) {
       const a  = document.createElement('a')
        a.href = url;
        a.download = name
        document.body.appendChild(a)
        a.click()
        a.remove()
    },
    animationFree() {
      animate(window,4200)

    },
    animationLearn() {
      animate(window,677)

    }
  },
}
</script>
<style lang='scss' scoped>
.plugInEn {
  background-image: linear-gradient(to right, #7f2f38, #2c0e22);
  color: #fff;
  padding-bottom: 150px;
  position: relative;
  .bg {
    position: absolute;
    top: 124px;
    left: 0;
    height: 624px;
    width: 100%;
    background: url('https://holodata.prometh.xyz/webMetaTrade/plugen-bg.png') no-repeat;
  }
  .cantainer {
    width: 62.55vw;
    margin: 0 auto;
    position: relative;
    .intro {
      height: 747px;
      background-color: #20202760;
      padding: 261px 5px 0;
      margin-bottom: 4px;
      .title {
        font-size: 50px;
        color: #22FF1D;
        font-family: AaHouDiHei;
      }
      .description {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        span {
          font-weight: 400;
          color: #c2bbc0;
        }
      }
      .intro-btn {
        margin-top: 95px;
        height: 45px;
        .free-trial {
          display: inline-block;
          width: 133px;
          height: 45px;
          background-color: #23FF1E;
          text-align: center;
          line-height: 45px;
          margin-right: 19px;
          cursor: pointer;
        }
        .learn-more {
          display: inline-block;
          width: 133px;
          height: 45px;
          border: 2px solid #23FF1E;
          text-align: center;
          cursor: pointer;
          line-height: 41px;
        }
      }
    }
    .imgs {
      text-align: center;
      .capture,.editing,.play {
        position: relative;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
        div {
          position: absolute;
          width: 100%;
          bottom: 23px;
          font-size: 28px;
        }
      }
    }
    .useCases {
      height: 848px;
      width: 100%;
      padding: 88px 20px 0;
      background: url('https://holodata.prometh.xyz/webMetaTrade/plugen-wang.png') no-repeat;
      background-size: 100% 100%;
      .useCases-title {
        font-size: 50px;
        font-weight: bold;
        color: #22FF1D;
        text-align: center;
      }
      .useCases-imgs {
        margin-top: 87px;
        display: flex;
        .useCases-box {
          text-align: center;
          margin-right: 15px;
          font-size: 28px;
          font-weight: bold;
          img {
            width: 100%;
            margin-bottom: 30px;
          }
        }
        .useCases-box1 {
          margin-right: 0;
        }
      }
    }
    .haloStudio {
      width: 100%;
      height: 1669px;
      background: url('https://holodata.prometh.xyz/webMetaTrade/plugen-lvmu.png') no-repeat;
      background-size: 100% 100%;
      padding: 80px 60px 0;
      .haloStudio-title {
        font-size: 50px;
        font-weight: bold;
      }
      .haloStudio-intro {
        margin: 60px 0 50px;
        font-size: 28px;
        color: #bfcbbd;
      }
      .haloStudio-img {
        margin-bottom: 60px;
      }
      .haloStudio-description1,.haloStudio-description2,.haloStudio-description3 {
        font-size: 28px;
        color: #aebdaa;
      }
      .haloStudio-description2 {
        margin: 80px 0
      }
    }
    .createNow {
      width: 100%;
      height: 1284px;
      background: url('https://holodata.prometh.xyz/webMetaTrade/plugen-create.png')no-repeat;
      background-size: 100% 100%;
      padding: 53px 50px 0;
      .createNow-title {
        font-size: 50px;
        font-weight: bold;
        color: #22FF1D;
        text-align: center;
        margin-bottom: 90px;
      }
      .createNow-imgs {
        margin-bottom: 80px;
      }
      .createNow-text {
        margin-bottom: 80px;
        color: #aba8aa;
        font-size: 28px;
        text-align: center;
      }
      .createNow-btn-box {
        font-size: 29px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .createNow-btn1{
          margin-right: 50px;
          width: 261px;
          height: 73px;
          background-color: #23FF1E;
          line-height: 73px;
          cursor: pointer;
        }
        .createNow-btn2 {
          margin-right: 50px;
          width: 261px;
          height: 73px;
          border: 2px solid #23FF1E;
          line-height: 69px;
          cursor: pointer;
        }
        .createNow-btn3 {
          width: 261px;
          height: 73px;
          background-color: #23FF1E;
          line-height: 73px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>